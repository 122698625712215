@media print {
    .mapboxgl-control-container {
        display: none !important;
    }
}

.flip-tools {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
    background: goldenrod;
    color: black;
    padding: 10px 20px;
    text-align: center;
    border-radius: 3px;
}